var combosClass = jQuery(".personal-order");
var productID_input_field = jQuery("#productID");
var buttonProdukt = jQuery(".personal-order");
var buttonGutschein = jQuery("#gutschein a");
var inputTextContainer = jQuery("#gutschein input");
// var abono_month_free = jQuery(".abono_month_free");
// var code_product_input = jQuery (".codigo-producto input");
// var description_product_input = jQuery (".description-producto textarea");
// var container_features = jQuery(".col_features_container .features > ul");
// var container_image = jQuery(".col_image_container > img");


$(document).ready(function(){

  combosClass.each(function(e) {
    $(this).click(function(){
        
        // //PRODUCT TITLES
        // var titleContainer_element = $('option:selected', this).parent().parent().parent().parent().parent().find("b");

        // //PRODUCTO CODE NAME
        // var product_id = $('option:selected', this).attr("data-code"); //to get selected text

        // //PRODUCT FINAL TITLE
        var finalTitle = $(this).attr("data-product");
        // alert(finalTitle);

        // var priceContainer_element = $('option:selected', this).parent().parent().parent().parent().parent().find(".price_product");

        // var priceMonth = $('option:selected', this).attr("data-price");

        // //COMBO INDEX NUMBER
        // var indexElement_selected = $('option:selected', this).index();
        // // alert(indexElement_selected);

        // $(this).parent().parent().parent().parent().find(".features > ul").addClass("features_hidden").removeClass("features_active");
        // $(this).parent().parent().parent().parent().find(".features > ul").eq(indexElement_selected).removeClass("features_hidden").addClass("features_active");

        // $(this).parent().parent().parent().parent().find(".col_image_container > img").addClass("image_hidden").removeClass("image_active");
        // $(this).parent().parent().parent().parent().find(".col_image_container > img").eq(indexElement_selected).removeClass("image_hidden").addClass("image_active");





        // priceContainer_element.html(priceMonth);
        // titleContainer_element.html(finalTitle);
        productID_input_field.val(finalTitle);
        // description_product_input.val(finalTitle); 
        
    });
});

      $("#switcher_sticky").click(function(){
        $("#list-gutschein-closer").fadeIn();
        $("body").addClass("fomular-opened");
        $(".list_gutscheine").addClass("opened");
      });
3
      buttonGutschein.click(function(){
            $("#list-gutschein-closer").fadeIn();
            $("body").addClass("fomular-opened");
            $(".list_gutscheine").addClass("opened");
        });

        // $('input:button').click(function () {
        //   $('#count').val($(this).data('quantity') * $('#product_price').text());
        // });

        $(".list_gutscheine p").each(function(e) {
          $(this).click(function(){
            inputTextContainer.val($(this).text());
            var valueGutschein = $(this).text();
            // alert(valueGutschein);
            $("#switcher_sticky").find("span").html(valueGutschein);
            $(".element").fadeOut();
            $(".list_gutscheine p").removeClass("selected");
            $("#showAll").removeClass("selected");
            $(this).addClass("selected");
            $(".element[data-value='" + valueGutschein +"']").fadeIn();
            window.location = '/#myUL';
            $(this).parent().removeClass("opened");
            $("body").removeClass("fomular-opened");
          });
      });

      $("#showAll").click(function(){
        $(".element").fadeIn();
        $(".list_gutscheine p").removeClass("selected");
        $(this).addClass("selected");
        $("#switcher_sticky").find("span").html("0");
        inputTextContainer.val("000");
        window.location = '/#myUL';
        $(this).parent().removeClass("opened");
        $("body").removeClass("fomular-opened");
      });

      $(".tutorial").click(function(){
        $("#closeFormular").fadeIn();
        $("body").addClass("fomular-opened");
        $("#steps").addClass("showed");
      });

    $("#closeFormular").click(function(){
        $("body").removeClass("fomular-opened");
        $("#closeFormular").fadeOut();
        $("#steps").removeClass("showed");
        $("#formular_product").removeClass("opened");
    });

    $("#list-gutschein-closer").click(function(){
      $("body").removeClass("fomular-opened");
      $("#list-gutschein-closer").fadeOut();
      $(".list_gutscheine").removeClass("opened");
  });

    

    buttonProdukt.each(function(e) {
        $(this).click(function(){
            $("#closeFormular").fadeIn();
            $("body").addClass("fomular-opened");
            $("#formular_product").addClass("opened");
        });
    });




    

});

//GETTING VALUE OF SELECT OPTION

(function() {
    'use strict';
    window.addEventListener('load', function() {
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      var forms = document.getElementsByClassName('produktForm');
      // Loop over them and prevent submission
      var validation = Array.prototype.filter.call(forms, function(form) {
        form.addEventListener('submit', function(event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          }
          else{
            swal("Vielen Dank!", "Wir werden uns so schnell wie möglich bei Ihnen melden", "success")
          }
          form.classList.add('was-validated');
        }, false);
      });
    }, false);
    
  })();
